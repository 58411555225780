export const requireAuth = (to, from, next) => {
  if (!localStorage.getItem("id_token")) {
    return next({
      name: "login",
    });
  } else {
    return next();
  }
};

export const requireUnAuth = (to, from, next) => {
  if (!localStorage.getItem("id_token")) {
    return next();
  } else {
    return next({
      name: "home",
    });
  }
};
