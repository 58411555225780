import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Company } from "@/store/modules/Types";

export interface CompanyInfo {
  invoices: Array<Company>;
}

@Module
export default class InvoicesModule extends VuexModule implements CompanyInfo {
  invoices: Array<Company> = [];

  /**
   * Get current user object
   * @returns User
   */
  get currentInvoices(): Array<Company> {
    return this.invoices;
  }

  @Mutation
  [Mutations.SET_INVOICES](invoices) {
    this.invoices = invoices;
  }

  @Action
  [Actions.GET_INVOICES](filter) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setFilter(filter);
      ApiService.get("invoices")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_INVOICES, data.data.rows);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
