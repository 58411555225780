import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { StatisticsInfo } from "@/store/modules/Types";

@Module
export default class StatisticsModule
  extends VuexModule
  implements StatisticsInfo
{
  statistics;
  users_line_chart;
  invoices_paid_line_chart;
  invoices_pending_line_chart;
  events;
  /**
   * Get current user object
   * @returns User
   */
  get currentStatistics() {
    return this.statistics;
  }

  get invoicesPaidLineChart() {
    return this.invoices_paid_line_chart;
  }

  get invoicesPendingLineChart() {
    return this.invoices_pending_line_chart;
  }

  get usersLineChart() {
    return this.users_line_chart;
  }

  @Mutation
  [Mutations.SET_STATISTICS](data) {
    this.statistics = data;
  }

  @Mutation
  [Mutations.SET_USER_LINE_CHART](data) {
    this.users_line_chart = data;
  }

  @Mutation
  [Mutations.SET_PAID_INVOICE_LINE_CHART](data) {
    this.invoices_paid_line_chart = data;
  }

  @Mutation
  [Mutations.SET_PENDING_INVOICE_LINE_CHART](data) {
    this.invoices_pending_line_chart = data;
  }

  @Mutation
  [Mutations.SET_EVENTS](data) {
    this.events = data;
  }

  @Action
  [Actions.GET_STATISTICS](filter) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setFilter(filter);
      ApiService.query("reports/total-cards")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATISTICS, data.data);
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_LATEST_EVENTS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setFilter();
      ApiService.query("reports/events")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_USER_LINE_CHART](type = "monthly") {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.query("/reports/lineChart/users/" + type)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER_LINE_CHART, data.rows);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PAID_INVOICE_LINE_CHART](type = "monthly") {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.query("/reports/lineChart/invoices_paid/" + type)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PAID_INVOICE_LINE_CHART, data.rows);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PENDING_INVOICE_LINE_CHART](type = "monthly") {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.query("/reports/lineChart/invoices_pending/" + type)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PENDING_INVOICE_LINE_CHART,
            data.rows
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
