import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ApplicationsModule from "@/store/modules/ApplicationsModule";
import CompaniesModule from "@/store/modules/CompaniesModule";
import StatisticsModule from "@/store/modules/StatisticsModule";
import InvoicesModule from "@/store/modules/InvoicesModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    CompaniesModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ApplicationsModule,
    InvoicesModule,
    StatisticsModule,
  },
});

export default store;
