import { requireAuth } from "@/core/helpers/authentication";
const refs = "contacts";
export default [
  {
    path: "/" + refs,
    name: refs,
    component: () => import("@/views/apps/" + refs + "/List.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/" + refs,
    name: "edit-" + refs,
    component: () => import("@/views/apps/" + refs + "/List.vue"),
    beforeEnter: requireAuth,
  },
];
