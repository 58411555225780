import { requireAuth } from "@/core/helpers/authentication";
const refs = "front-settings";
export default [
  {
    path: "/" + refs,
    name: refs,
    component: () => import("@/views/apps/" + refs + "/Card.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/" + refs + "/show",
    name: "show-" + refs,
    component: () => import("@/views/apps/" + refs + "/List.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/" + refs + "/create",
    name: "create-" + refs,
    component: () => import("@/views/apps/" + refs + "/EditOrNew.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/" + refs + "/edit/:id",
    name: "edit-" + refs,
    component: () => import("@/views/apps/" + refs + "/EditOrNew.vue"),
    beforeEnter: requireAuth,
  },
];
