import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Company } from "@/store/modules/Types";

export interface CompanyInfo {
  companies: Array<Company>;
}
@Module
export default class CompaniesModule extends VuexModule implements CompanyInfo {
  companies: Array<Company> = [];

  /**
   * Get current user object
   * @returns User
   */
  get currentCompanies(): Array<Company> {
    return this.companies;
  }

  @Mutation
  [Mutations.SET_COMPANIES](companies) {
    this.companies = companies;
  }

  @Action
  [Actions.GET_COMPANIES](filter) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setFilter(filter);
      ApiService.get("companies")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COMPANIES, data.data.rows);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
