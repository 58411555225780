import Editor from "@tinymce/tinymce-vue";
import { App } from "vue";

(window as any).editor_apiKey =
  "4dbppxfphaerz89zew54awwbefazk738o6cxt6wviw0s828g";
(window as any).editor_menubar =
  "file edit view insert format tools table help";
(window as any).editor_plugins = [
  "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
];
(window as any).editor_toolbar =
  "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog typography | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat";

export function initEditor(app: App<Element>) {
  app.component("editor", Editor);
}
