import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

// mixen routers
import AuthRoutes from "@/router/modules/auth";
import AppRoutes from "@/router/modules/app";
import CompanyRoutes from "@/router/modules/company";
import NotificationRoutes from "@/router/modules/notification";
import InvoicesRoutes from "@/router/modules/invoices";
import PageRoutes from "@/router/modules/page";
import ReportRoutes from "@/router/modules/report";
import RoleRoutes from "@/router/modules/role";
import SettingRoutes from "@/router/modules/setting";
import FrontSettingRoutes from "@/router/modules/front-setting";
import UserRoutes from "@/router/modules/user";
import EmployeeRoutes from "@/router/modules/employee";
import PendingInvoicesRoutes from "@/router/modules/pending-invoices";
import PaymentMethodRoutes from "@/router/modules/paymentMethod";
import CountryRoutes from "@/router/modules/country";
import ContactRoutes from "@/router/modules/contact";
import ContractRoutes from "@/router/modules/contracts";

//frontend routes
import AboutRoutes from "@/router/modules/about";
import ServiceRoutes from "@/router/modules/service";
import SliderRoutes from "@/router/modules/slider";
import TestimonialRoutes from "@/router/modules/testimonial";
import FaqRoutes from "@/router/modules/faq";
import ClientRoutes from "@/router/modules/client";
import WorkFieldsRoutes from "@/router/modules/workField";

let routes: Array<RouteRecordRaw> = [];
const dashboard: Array<RouteRecordRaw> = [];

routes = routes.concat(
  [
    {
      path: "/",
      redirect: "home",
      component: () => import("@/layout/Layout.vue"),
      children: dashboard.concat(
        AboutRoutes,
        FaqRoutes,
        WorkFieldsRoutes,
        ClientRoutes,
        ServiceRoutes,
        ContractRoutes,
        SliderRoutes,
        TestimonialRoutes,
        AppRoutes,
        CountryRoutes,
        ContactRoutes,
        PaymentMethodRoutes,
        CompanyRoutes,
        NotificationRoutes,
        InvoicesRoutes,
        PageRoutes,
        ReportRoutes,
        RoleRoutes,
        SettingRoutes,
        FrontSettingRoutes,
        UserRoutes,
        EmployeeRoutes,
        PendingInvoicesRoutes
      ),
    },
  ],
  [
    {
      path: "/",
      component: () => import("@/components/page-layouts/Auth.vue"),
      children: AuthRoutes,
    },
  ],
  [
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/apps/authentication/Error404.vue"),
    },
    {
      path: "/pending-invoices",
      name: "pending-invoices",
      component: () => import("@/views/apps/pending-invoices/List.vue"),
    },
    {
      path: "/pending-invoices/next-payment/:id",
      name: "show-" + "pending-invoices",
      component: () => import("@/views/apps/pending-invoices/Show.vue"),
    },
    {
      path: "/500",
      name: "500",
      component: () => import("@/views/apps/authentication/Error500.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      redirect: "/404",
    },
    {
      path: "/:pathMatch(.*)",
      name: "bad-not-found",
      redirect: "/404",
    },
  ]
  //
);

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
