import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import router from "@/router";
const USER_KEY = "user_key" as string;
const ROLE_KEY = "role_key" as string;
const CURRENCY_KEY = "currency_key" as string;

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description get currency form localStorage
   */
  public static getCurrency() {
    return window.localStorage.getItem(CURRENCY_KEY);
  }
  /**
   * @description get role form localStorage
   */
  public static getRole() {
    return window.localStorage.getItem(ROLE_KEY);
  }
  /**
   * @description user form localStorage
   */
  public static getUser(): any {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return JSON.parse(window.localStorage.getItem(USER_KEY));
  }

  /**
   * @description save token into localStorage
   * @param currency: string
   */
  public static saveCurrency(currency) {
    window.localStorage.setItem(CURRENCY_KEY, currency);
  }
  /**
   * @description save role into localStorage
   * @param role: string
   */
  public static saveRole(role: string) {
    window.localStorage.setItem(ROLE_KEY, role);
  }

  /**
   * @description save token into localStorage
   * @param user: string
   */
  public static saveUser(user: any) {
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  /**
   * @description remove token form localStorage
   */
  public static destroyRole() {
    window.localStorage.removeItem(ROLE_KEY);
  }
  /**
   * @description remove token form localStorage
   */
  public static destroyUser() {
    window.localStorage.removeItem(USER_KEY);
  }
  /**
   * @description remove token form localStorage
   */
  public static destroyCurrency() {
    window.localStorage.removeItem(CURRENCY_KEY);
  }

  public logout() {
    // localStorage.removeItem('adminToken')
    // localStorage.removeItem('adminInfo')
    //
    // router.push('/dashboard/login').catch(() => {})
    //
  }

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL =
      (window as any).domainName + "/api/v1/backend";

    ApiService.vueInstance.axios.defaults.headers.common["locale"] =
      localStorage.getItem("lang") ? localStorage.getItem("lang") : "ar";

    ApiService.vueInstance.axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        config.headers.locale = localStorage.getItem("lang")
          ? localStorage.getItem("lang")
          : "ar";
        return config;
      }
    );

    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const response = error.response;
        if (response.status == "404") {
          return router.push("/404").catch();
        } else if (response.status == "401") {
          localStorage.clear();
          return (window.location.href = "/#/login");
        } else if (response.status == "500") {
          router.push("/500").catch();
        }

        return Promise.reject(error);
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;

    ApiService.vueInstance.axios.defaults.headers.common[
      "Content"
    ] = `application/json`;

    ApiService.vueInstance.axios.defaults.headers.common[
      "Content-type"
    ] = `application/json`;

    ApiService.vueInstance.axios.defaults.headers.common[
      "Accept-type"
    ] = `application/json`;

    ApiService.vueInstance.axios.defaults.headers.common[
      "Accept"
    ] = `application/json`;
  }

  /**
   * @description set the default HTTP request body
   */
  public static setFilter(filter = {}): void {
    ApiService.vueInstance.axios.defaults.params = filter;
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params = {} as AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      return error;
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`).catch((error) => {
      return error;
    });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(resource: string, params?: any): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
}

export default ApiService;
