import objectPath from "object-path";
import merge from "deepmerge";
import layoutConfig from "@/core/config/DefaultLayoutConfig";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";
import ApiService from "@/core/services/ApiService";

export interface AppConfigTrans {
  title: string;
  body: string;
}

export interface AppConfig {
  ar: AppConfigTrans;
  en: AppConfigTrans;
  image: string;
}

interface StoreInfo {
  config: LayoutConfigTypes;
  initial: LayoutConfigTypes;
  app_config: AppConfig;
}

@Module
export default class ConfigModule extends VuexModule implements StoreInfo {
  config = layoutConfig;
  initial = layoutConfig;
  app_config;

  /**
   * Get config from layout config
   * @returns {function(path, defaultValue): *}
   */
  get layoutConfig() {
    return (path, defaultValue) => {
      return objectPath.get(this.config, path, defaultValue);
    };
  }

  get getAppGlobalConfig() {
    return this.app_config;
  }

  @Mutation
  [Mutations.SET_LAYOUT_CONFIG](payload): void {
    this.config = payload;
  }

  @Mutation
  [Mutations.RESET_LAYOUT_CONFIG]() {
    this.config = Object.assign({}, this.initial);
  }

  @Mutation
  [Mutations.OVERRIDE_LAYOUT_CONFIG](): void {
    this.config = this.initial = Object.assign(
      {},
      this.initial,
      JSON.parse(window.localStorage.getItem("config") || "{}")
    );
  }

  @Mutation
  [Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG](payload): void {
    this.config = merge(this.config, payload);
  }

  @Mutation
  [Mutations.SET_APP_GLOBAL_CONFIG](payload): void {
    this.app_config = payload;
  }

  @Action
  [Actions.APP_GLOBAL_CONFIG]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("settings")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_APP_GLOBAL_CONFIG, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
