import { requireUnAuth } from "@/core/helpers/authentication";

export default [
  {
    path: "/login",
    name: "login",
    component: () =>
      import("@/views/apps/authentication/basic-flow/SignIn.vue"),
    beforeEnter: requireUnAuth,
  },
];
