enum Actions {
  // action types
  GET_LATEST_EVENTS = "getLatestEvents",
  GET_PENDING_INVOICE_LINE_CHART = "getPendingInvoiceLineChart",
  GET_INVOICES = "getInvoices",
  GET_USER_LINE_CHART = "getUserLineChart",
  GET_PAID_INVOICE_LINE_CHART = "getPaidInvoiceLineChart",
  GET_STATISTICS = "getStatistics",
  GET_COMPANIES = "getCompanies",
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  APP_GLOBAL_CONFIG = "appGlobalConfig",
  GET_APPLICATIONS = "get_applications",
}

enum Mutations {
  // mutation types
  SET_EVENTS = "setEvents",
  SET_INVOICES = "setInvoices",
  SET_STATISTICS = "setStatistics",
  SET_COMPANIES = "setCompanies",
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_APP_GLOBAL_CONFIG = "setAppGlobalConfig",
  SET_APPLICATIONS = "setApplications",
  SET_USER_LINE_CHART = "setUserLineChart",
  SET_PAID_INVOICE_LINE_CHART = "setPaidInvoiceLineChart",
  SET_PENDING_INVOICE_LINE_CHART = "setPendingInvoiceLineChart",
}

export { Actions, Mutations };
