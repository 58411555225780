import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface Application {
  id: number;
  slug: string;
  icon: string;
  authority: boolean;
  name: string;

  en: any;
  ar: any;
}

export interface ApplicationInfo {
  applications: Array<Application>;
}

@Module
export default class ApplicationsModule
  extends VuexModule
  implements ApplicationInfo
{
  applications: Array<Application> = [];
  /**
   * Get current Application array
   * @returns Array<Application>
   */
  get currentApplications(): Array<Application> {
    return this.applications;
  }

  @Mutation
  [Mutations.SET_APPLICATIONS](applications) {
    this.applications = applications;
  }
  @Action
  [Actions.GET_APPLICATIONS]() {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.query("applications")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_APPLICATIONS, data.data.rows);
          resolve();
        })
        .catch(({ response }) => {
          console.log(response);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
