import { requireAuth } from "@/core/helpers/authentication";

export default [
  {
    path: "/customize-design",
    name: "customize-design",
    component: () => import("@/views/apps/customize-design/List.vue"),
  },
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: () => import("@/views/apps/dashboard/List.vue"),
    beforeEnter: requireAuth,
    name: "home",
  },
  {
    path: "/dashboard",
    redirect: "/home",
  },
  {
    path: "/applications",
    name: "applications",
    component: () => import("@/views/apps/applications/List.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: "/account-setting",
    name: "account-setting",
    component: () => import("@/views/apps/companies/EditOrNew.vue"),
    beforeEnter: requireAuth,
  },
];
